import React, { useState } from 'react';
import axiosInstance from '../../../utils/axios';
import { toast } from 'react-toastify';
import Button from '@mui/material/Button';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogTitle from '@mui/material/DialogTitle';
import { NepaliDatePicker } from 'nepali-datepicker-reactjs';
import NepaliDate from 'nepali-date-converter';

const ReportDownloadModal = ({
  showReportDownload,
  toggleReportDownloadModal,
  userId,
}) => {
  const dateNow = new Date();
  const defaultToDate = new NepaliDate(dateNow).format('YYYY-MM-DD');
  dateNow.setDate(dateNow.getDate() - 7);
  const defaultFromDate = new NepaliDate(dateNow).format('YYYY-MM-DD');
  const [selectedFromDate, setSelectedFromDate] = useState(defaultFromDate);
  const [selectedToDate, setSelectedToDate] = useState(defaultToDate);

  const downloadReport = () => {
    axiosInstance
      .get(`/v1/users/${userId}/generate-reports`, {
        params: {
          from: selectedFromDate,
          to: selectedToDate,
        },
        responseType: 'blob',
      })
      .then((resp) => {
        if (resp.status === 200) {
          const url = window.URL.createObjectURL(new Blob([resp.data]));
          const link = document.createElement('a');
          link.href = url;
          link.setAttribute('download', 'report.xlsx');
          document.body.appendChild(link);
          link.click();
          link.remove();
          toast.success('Report downloaded successfully!');
          toggleReportDownloadModal();
          toast.error(resp.data.message);
        }
      })
      .catch((error) => {
        const errorMessage =
          error.response.data.message || 'Failed to download report.';
        toast.error(errorMessage);
      });
  };

  const handleFromDateChange = (value) => {
    const nepaliDate = new NepaliDate(value).format('YYYY-MM-DD');
    setSelectedFromDate(nepaliDate);
  };

  const handleToDateChange = (value) => {
    const nepaliDate = new NepaliDate(value).format('YYYY-MM-DD');
    setSelectedToDate(nepaliDate);
  };

  return (
    <div>
      {' '}
      <Dialog
        open={showReportDownload}
        onClose={toggleReportDownloadModal}
        disableEnforceFocus
        aria-labelledby='alert-dialog-title'
        aria-describedby='alert-dialog-description'
        maxWidth='md'
      >
        <DialogTitle id='alert-dialog-title'>{'Download Report'}</DialogTitle>
        <div
          style={{
            display: 'flex',
            justifyContent: 'space-between',
            gap: '2rem',
            paddingLeft: '8rem',
            paddingBottom: '8rem',
            paddingRight: '8rem',
            minWidth: '600px',
            overflowY: 'auto',
          }}
        >
          <div
            style={{
              display: 'flex',
              flexDirection: 'column',
              flex: 1,
            }}
          >
            <label
              style={{
                marginBottom: '0.5rem',
                fontWeight: 'bold',
                fontSize: '16px',
                color: '#333',
              }}
            >
              From:
            </label>
            <NepaliDatePicker
              inputClassName='form-control'
              className='datepicker'
              portalTarget={document.body}
              onChange={handleFromDateChange}
              options={{ calenderLocale: 'ne', valueLocale: 'en' }}
              value={selectedFromDate}
            />
          </div>

          <div
            style={{
              display: 'flex',
              flexDirection: 'column',
              alignItems: 'flex-start',
              flex: 1,
            }}
          >
            <label
              style={{
                marginBottom: '0.5rem',
                fontWeight: 'bold',
                fontSize: '16px',
                color: '#333',
              }}
            >
              To:
            </label>
            <NepaliDatePicker
              inputClassName='form-control'
              className='datepicker'
              portalTarget={document.body}
              onChange={handleToDateChange}
              options={{ calenderLocale: 'ne', valueLocale: 'en' }}
              value={selectedToDate}
            />
          </div>
        </div>

        <DialogActions>
          <Button onClick={downloadReport}>Download</Button>
          <Button onClick={toggleReportDownloadModal} autoFocus>
            Cancel
          </Button>
        </DialogActions>
      </Dialog>
    </div>
  );
};

export default ReportDownloadModal;
